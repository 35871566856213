import React, {Component} from "react";

class PricePreview extends Component {

    render() {
        return (
            <div className='col-ac font-1 ml-20'>
                <p className='mb-4 cream body1'>
                    Price
                </p>
                <div className='row-ac'>
                    <div className='relative'>
                        <div className='pointer headline-sm cream ph-12' style={{ fontSize: this.props.mobile && 36}}>
                            Free
                        </div>
                        {this.props.batchSize === 2 &&
                        <div className='absolute' style={{right:20}}>
                            (Still)
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default PricePreview
