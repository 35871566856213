import React, {Component} from "react";
import navlogo from "../assets/img/face.png";
import Discord from "../assets/svg/svg/Discord";
import Twitter from "../assets/svg/svg/Twitter";


class Navbar extends Component {
    render() {
        return (
            <div className='row-ac-jb absolute w-100p pt-12' style={{zIndex: 100, height: 80}}>
                <div className='row-ac' style={{marginTop: !this.props.mobile && -12}} onClick={() => {
                    this.props.history.push("/")
                    window.location.reload()
                    window.scrollTo({
                        top: 0,
                    });
                }}>
                    <img src={navlogo} style={{width: 40}}
                         className={this.props.mobile ? 'pl-12 pointer' : 'pl-80 pointer'}/>
                    <p className='ml-12'>MIMS</p>
                </div>
                {!this.props.hideRHS &&
                <div className={this.props.mobile ? 'row-ac pr-12' : 'row-ac pr-80'}>
                    <div className='col-ac'>
                        <a href='https://etherscan.io/address/0x2183533bbc58c9afef81bd4165bafd9f7654ed16' target='_blank'>
                            <div className='font-1 button' style={{fontSize: this.props.mobile ? 12 : 16}}>
                                Contract
                            </div>
                        </a>
                    </div>
                    <div className={this.props.mobile ? 'ml-12 mt-12 hover' : 'ml-40 mt-12 hover'}>
                        <a href='https://twitter.com/mims_lol' target='_blank'>
                            <Twitter fill={'black'}/>
                        </a>
                    </div>
                    {/*<div>*/}
                    {/*    <a href='' target='_blank'>*/}
                    {/*        <Discord fill={'#FFF3D4'}/>*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                </div>
                }
            </div>
        )
    }
}

export default Navbar
