import {motion} from "framer-motion";
import React, {Component} from "react";
import Modal from "react-modal";
import metamask from '../../assets/img/mm.png'
import walletConnect from '../../assets/img/walletconnect.png'
import Close from "../../assets/svg/svg/Close"

class ConnectWalletModal extends Component {

    metamaskPressed = () => {
        this.props.toggle()
        this.props.triggerProvider("metamask")
    }

    walletConnectPressed = () => {
        this.props.toggle()
        this.props.triggerProvider("walletconnect")
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.toggle}
                className="Modal"
                overlayClassName="Overlay"
            >
                <motion.div initial={{opacity: 0, transform: 'scale(1) translateY(-50%)'}}
                            animate={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            className='modal-body relative'>
                    <div className='close-modal-x pointer' onClick={this.props.toggle}>
                        <Close fill={'#3499de'}/>
                    </div>
                    <div className='col-ac' style={{color:'white'}}>
                        <div className='col-ac font-1 mb-40'>
                            <div className={this.props.mobile && 'mt-40'} style={{fontSize: this.props.mobile && 14}}>
                                Connect your wallet
                            </div>
                        </div>
                        <div className={this.props.mobile ? 'col-ac' : 'row-ac-jc flex-wrap'}>
                            <div className='wallet-option'
                                 onClick={this.metamaskPressed}
                                 style={{width: this.props.mobile && 150, margin: this.props.mobile && 12}}>
                                <img src={metamask}
                                     style={{width: this.props.mobile ? 40 : 75, height: this.props.mobile ? 40 : 75}}
                                     className='mt-1'/>
                                <p className='mv-8 font-1'>Metamask</p>
                            </div>
                            <div className='wallet-option'
                                 onClick={this.walletConnectPressed}
                                 style={{width: this.props.mobile && 150, margin: this.props.mobile && 12}}>
                                <img src={walletConnect}
                                     style={{width: this.props.mobile ? 40 : 75, height: this.props.mobile ? 40 : 75}}
                                     className='mt-1'/>
                                <p className='mv-8 font-1'>WalletConnect</p>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </Modal>
        )
    }
}

export default ConnectWalletModal
