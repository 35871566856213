import './App.css';
import './styles/Formatting.css'
import './styles/Text.css'
import './styles/Components.css'

import team1 from '../src/assets/img/team/MIMS (129).png'
import team2 from '../src/assets/img/team/MIMS (280).png'
import team3 from '../src/assets/img/team/MIMS (370).png'
import line from '../src/assets/img/line.png'
import dots from '../src/assets/img/dots.png'
import face from '../src/assets/img/face.png'
import face2 from '../src/assets/img/face2.png'
import sharp from '../src/assets/img/sharp.png'
import horns from '../src/assets/img/horns.png'
import arrow from '../src/assets/img/arrow.png'
import beans from '../src/assets/img/beans.png'
import free from '../src/assets/img/free.png'
import blob from '../src/assets/img/blob.png'
import tally from '../src/assets/img/tally.png'
import {Component} from "react";
import MintComponent from "./components/MintComponent";
import spa from '../src/assets/audio/spa.mp3'
import {wait} from "./utils/helpers";
import Navbar from "./components/Navbar";

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mobile: false,
            muted: false
        }
    }

    componentDidMount() {
        console.log(window.innerWidth)
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        wait(500).then(() =>
            this.setState({muted: false})
        )
    }

    resize() {
        this.setState({
            mobile: window.innerWidth <= 760,
            tablet: (window.innerWidth >= 660 && window.innerWidth <= 1200)
        });
    }

    render() {
        return (
            <div className='col-ac-jb relative' style={{minHeight: '100vh', overflowX: 'hidden'}}>
                <div className='screen-cover'/>
                <Navbar mobile={this.state.mobile}/>
                <div className='pt-80 relative'>
                    <p style={{fontSize: this.state.mobile ? 32 : 50}}>
                        MakeItMakeSense
                    </p>
                    <img className='o5' src={line} style={{width: 200}}/>
                    <img className={this.state.mobile ? 'absolute floating2 bg-face-mobile o5' : 'absolute floating2 bg-face o5'} src={face}/>
                    <img className='absolute floating2 bg-dots o5' src={dots}/>
                    <img className={this.state.mobile ? 'absolute floating bg-face2-mobile o5' : 'absolute floating bg-face2 o5'} src={face2}/>
                </div>
                <div className='relative'>
                    <MintComponent mobile={this.state.mobile}/>
                    <img className='absolute floating bg-face o5' src={beans}/>
                    <img className='absolute floating2 bg-arrow o5' src={blob}/>
                    <img className='absolute floating bg-face o5' style={{left: 100, width: 60}} src={tally}/>
                </div>
                <div className='relative w-100p'>
                    <div className={this.state.mobile ? ' row-ac' : 'absolute row-ac'}
                         style={{left: 20, top: -200, transform: 'rotate(12deg)'}}>
                        <div style={{fontSize: this.state.mobile ? 54 : 162}}>
                            NO
                        </div>
                        <div className='ml-12' style={{fontSize: this.state.mobile ? 26 : 40}}>
                            <div>
                                Discord
                            </div>
                            <div>
                                Roadmap
                            </div>
                            <div>
                                Whitelist
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-ac relative'>
                    <div className='title'>Team</div>
                    <div className='row-ac-jc' style={{flexWrap: 'wrap'}}>
                        <div className='team-tile'>
                            <img src={team1} className='team-img w-100p'/>
                        </div>
                        <div className='team-tile'>
                            <img src={team2} className='team-img w-100p'/>
                        </div>
                        <div className='team-tile'>
                            <img src={team3} className='team-img w-100p'/>
                        </div>
                    </div>
                    <img className='absolute floating bg-sharp o5' src={sharp}/>
                    <img className='absolute floating2 bg-horns o5' src={horns}/>
                    <img className='absolute floating bg-arrow o5' src={arrow}/>
                    <img className='absolute floating2 bg-free o5' src={free}/>
                </div>
                <div className='pb-80 mt-100 ph-16 text-center'>
                    <div style={{wordBreak:'break-all', position:"relative", zIndex:2}}>
                        Nothinginthismarketmakessense.peoplegrindmintflipapebuysellspeculatemillionsofdollarsaday.fuckallthatitallcomesdowntoyourinterpretation.
                    </div>
                </div>
                <div style={{bottom: 10, right: 16, position: "fixed"}}>
                    <audio controls autoPlay={true} muted={this.state.muted}>
                        <source src={spa} type="audio/mpeg"/>
                        Your browser does not support the audio element.
                    </audio>
                </div>
            </div>
        )
    }
}

export default App
