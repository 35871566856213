import {motion} from "framer-motion";
import React, {Component} from "react";
import Modal from "react-modal";
import Close from "../../assets/svg/svg/Close";
import check from '../../assets/img/check.PNG'

class MintSuccessfulModal extends Component {

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.toggle}
                className="Modal"
                overlayClassName="Overlay"
            >
                <motion.div initial={{opacity: 0, transform: 'scale(1) translateY(-100%)'}}
                            animate={{opacity: 1, transform: 'scale(1) translateY(-0%)'}}
                            className='modal-body relative'>
                    <div className='close-modal-x' onClick={this.props.toggle}>
                        <Close fill={'#3499de'}/>
                    </div>
                    <div className='col-ac cream' style={{width: this.props.mobile ? '70vw' : '50vw'}}>
                        <div className='col-ac font-1 mb-40' style={{color: 'white'}}>
                            <div className={this.props.mobile && 'mt-40'}
                                 style={{fontSize: this.props.mobile ? 14 : 32}}>
                                ohbaby!
                            </div>
                            <img src={check} className={this.props.mobile ? 'w-50p' : 'w-25p'}/>
                            <div style={{textAlign: 'center', fontSize: this.props.mobile && 14}} className='mt-32'>
                                Thanksfortakingpartinourmint!You'llbeabletoseeyournew 1 of 1 handdrawnpieceinyourwalletsoon.Hopethatmakessense.
                            </div>
                        </div>
                        <div
                            className='button font-1 ml-0 mr-0'
                            style={{fontSize: this.props.mobile && 20, color:'black', backgroundColor:'white'}}
                            onClick={() => this.setState({connectModalVisible: true})}
                        >
                            Bye
                        </div>
                    </div>
                </motion.div>
            </Modal>
        )
    }
}

export default MintSuccessfulModal
