import React, {Component} from "react";
import {MAX_SUPPLY} from "../../mint/mint_consts";

class MintProgress extends Component {

    render() {
        const numberPosition = this.props.pctMinted < 2 ? 0 : this.props.pctMinted - 3
        const overflowBreakpoint = this.props.mobile ? 75 : 80
        return (
            <div className='mb-20 cream text-center' style={{width: this.props.mobile ? '80vw' : '50vw'}}>
                <p className='title2 font-1 mb-20 mt-0 cream' style={{fontSize: this.props.mobile && 24}}>
                    {this.props.pctMinted === 100 ? 'Mint Complete' : 'Minting Now'}
                </p>
                <p className='text-center mb-12 font-1'>
                    {this.props.pctMinted}% Minted
                    {(this.props.pctMinted > overflowBreakpoint && this.props.pctMinted !== 100 && !this.props.mobile) &&
                    <span>
                        &nbsp;- Only <span style={{color: '#3499de'}}>&nbsp;{MAX_SUPPLY - this.props.numMinted}&nbsp;</span> left!
                    </span>
                    }
                </p>
                {(this.props.pctMinted > overflowBreakpoint && this.props.pctMinted !== 100 && this.props.mobile) &&
                <div className='font-1 mb-20'>
                    Only <span style={{color: '#3499de'}}>&nbsp;{MAX_SUPPLY - this.props.numMinted}&nbsp;</span> left!
                </div>
                }
                <div className='w-100p' style={{backgroundColor: 'rgba(179,244,253,0.38)'}}>
                    <div
                         style={{width: `${this.props.pctMinted}%`, height: 8, backgroundColor:'#3499de'}}>
                    </div>
                </div>
                <div className='row-ac-jb mt-4'>
                    {this.props.pctMinted < overflowBreakpoint &&
                    <p className='font-1 mt-0'
                       style={{opacity: .5, marginLeft: `${numberPosition}%`, fontSize: this.props.mobile && 12}}>
                        {this.props.numMinted}
                    </p>
                    }
                    <p className='font-1 mt-0'
                       style={{opacity: .5, marginLeft: "auto", fontSize: this.props.mobile && 12}}>
                        {this.props.total}
                    </p>
                </div>
            </div>
        )
    }
}

export default MintProgress
