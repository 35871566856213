import React from "react";

function SvgTwitter(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            fill="none"
            viewBox="0 0 26 20"
            {...props}
        >
            <path
                d="M7.548 19.75c9.056 0 14.01-7.503 14.01-14.01 0-.213 0-.425-.015-.636A10.02 10.02 0 0024 2.555a9.815 9.815 0 01-2.828.775A4.94 4.94 0 0023.337.606a9.865 9.865 0 01-3.127 1.196 4.929 4.929 0 00-8.391 4.49A13.98 13.98 0 011.67 1.148 4.928 4.928 0 003.195 7.72 4.88 4.88 0 01.96 7.105v.062a4.926 4.926 0 003.95 4.827 4.914 4.914 0 01-2.223.084 4.93 4.93 0 004.6 3.42A9.878 9.878 0 010 17.538a13.941 13.941 0 007.548 2.209"
                fill={props.fill || "currentColor"}
            />
        </svg>
    );
}

export default SvgTwitter;
