import React, {Component} from "react";

class QuantitySelect extends Component {
    state = {
        hoveringOne:false,
        hoveringTwo:false
    }

    render() {
        return (
            <div className='col-ac font-1 mr-20'>
                <p className='mb-4 cream body1'>
                    How Many?
                </p>
                <div className='row-ac'>
                    <div className='pointer headline-sm cream ph-12 hover'
                         // onClick={() => this.props.setBatchSize(1)}
                         // onMouseEnter={() => this.setState({hoveringOne:true})}
                         // onMouseLeave={() => this.setState({hoveringOne:false})}
                         style={{
                             opacity: this.props.batchSize === 1 ? 1 : this.state.hoveringOne ? .7 : .5,
                             fontSize: this.props.mobile && 36
                         }}>
                        One
                    </div>
                    {/*<p className='cream mh-8'>*/}
                    {/*    Or*/}
                    {/*</p>*/}
                    {/*<div className='pointer headline-sm cream ph-12 cream-h hover'*/}
                    {/*      onClick={() => this.props.setBatchSize(2)}*/}
                    {/*           onMouseEnter={() => this.setState({hoveringTwo:true})}*/}
                    {/*     onMouseLeave={() => this.setState({hoveringTwo:false})}*/}
                    {/*     style={{*/}
                    {/*         opacity: this.props.batchSize === 2 ? 1 : this.state.hoveringTwo ? .7 : .5,*/}
                    {/*         fontSize: this.props.mobile && 36*/}
                    {/*     }}>*/}
                    {/*    2*/}
                    {/*</div>*/}
                </div>
            </div>
        );
    }
}

export default QuantitySelect
