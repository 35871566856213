import React, {Component} from "react";
import MintProgress from "./Minting/MintProgress";
import Spinner from "./Atoms/Spinner";
import QuantitySelect from "./Minting/QuantitySelect";
import PricePreview from "./Minting/PricePreview";
import ConnectWalletModal from "./Modals/ConnectWalletModal";
import MintSuccessfulModal from "./Modals/MintSuccessfulModal";
import {getTotalSupply, publicMintNow, triggerProvider} from "../mint/mintFunctions";
import {MAX_SUPPLY} from "../mint/mint_consts";


class MintComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            walletAddress: "",
            connected: false,
            connectModalVisible: false,
            pctMinted: 0,
            numMinted: 0,
            total: MAX_SUPPLY.toLocaleString(),
            batchSize: 1,
            loading: false,
            mintSuccessModal: false,
        }
        this.ethersProvider = undefined
    }



    componentDidMount() {
        this.getTotalSupply();
    }

    getTotalSupply = async () => {
        const { totalSupply, pctMinted } = await getTotalSupply()
        this.setState({numMinted: totalSupply, pctMinted: pctMinted})
    }

    publicMint = async () => {
        this.setState({loading: true});
        await publicMintNow(this.state.walletAddress, this.state.batchSize, this.provider)
        this.setState({mintSuccessModal: true, loading: false})
    }

    triggerProvider = async (mode) => {
        const { walletAddress, provider, ethersProvider } = await triggerProvider(mode);
        this.setState({connected: true, walletAddress: walletAddress, mode: mode})
        this.ethersProvider = ethersProvider;
        this.provider = provider;
    }

    render() {
        return (
            <div className='col-ac'>
                <MintProgress
                    numMinted={this.state.numMinted}
                    total={this.state.total}
                    pctMinted={this.state.pctMinted}
                    mobile={this.props.mobile}
                />
                <div className='row-ac'>
                    <QuantitySelect
                        batchSize={this.state.batchSize}
                        setBatchSize={(size) => this.setState({batchSize: size})}
                        mobile={this.props.mobile}
                    />
                    <PricePreview
                        batchSize={this.state.batchSize}
                        mobile={this.props.mobile}
                    />
                </div>
                <div className='mt-20 text-center'>
                    {this.state.pctMinted === 100 ?
                        <div className='title2 font-1' style={{color: '#6b3530'}}>
                            SOLD OUT!
                        </div>
                        :
                        <>
                            {this.state.connected ?
                                <div
                                    className='button font-1'
                                    style={{fontSize: this.props.mobile && 20, width: 80}}
                                    onClick={this.publicMint}
                                >
                                    {this.state.loading ?
                                        <Spinner size={20}/>
                                        :
                                        <>
                                            Mint
                                        </>
                                    }
                                </div>
                                :
                                <div
                                    className='button font-1'
                                    style={{fontSize: this.props.mobile && 20}}
                                    onClick={() => this.setState({connectModalVisible: true})}
                                >
                                    Connect Wallet
                                </div>
                            }
                        </>
                    }
                    <div style={{height: 41}}>
                        {this.state.connected &&
                        <div className='font-1 cream mt-20' style={{fontSize: 14}}>
                            <div style={{opacity: .5}}>
                                Wallet
                            </div>
                            <div>
                                {this.state.walletAddress.slice(0, 4)}...{this.state.walletAddress.slice(-4)}
                            </div>
                        </div>
                        }
                    </div>
                </div>
                <ConnectWalletModal
                    mobile={this.props.mobile}
                    isOpen={this.state.connectModalVisible}
                    toggle={() => this.setState({connectModalVisible: false})}
                    triggerProvider={this.triggerProvider}
                />
                <MintSuccessfulModal
                    mobile={this.props.mobile}
                    isOpen={this.state.mintSuccessModal}
                    toggle={() => this.setState({mintSuccessModal: false})}
                />
            </div>
        );
    }
}

export default MintComponent
