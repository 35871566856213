import {
    CONTRACT_ADDRESS, defaultProvider,
    INFURAID,
    MAINNET_CHAIN_ID, MAX_SUPPLY,
    MINT_PRICE_WEI,
    mintContract,
    PUBLIC_MINT_FUNCTION_SELECTOR
} from "./mint_consts";
import {ethers} from "ethers"
import WalletConnectProvider from "@walletconnect/ethereum-provider";

export const getTotalSupply = async () => {
    console.log("SUPPLY")
    const readContract = mintContract.connect(defaultProvider)
    let totalSupply = parseInt(await readContract.totalSupply())
    return { totalSupply : totalSupply, pctMinted: parseInt(parseInt(totalSupply) / MAX_SUPPLY * 100) }
}

export const triggerProvider = async (mode) => {
    let provider;
    let address;
    if (mode === "metamask") {
        if (window.ethereum) {
            console.log("CONNECTED TO ETHEREUM")
            provider = window.ethereum;
            address = await provider.request({method: 'eth_requestAccounts'})
            address = address[0]
            // METAMASK CHECK CONNECTION
        } else {
            alert("No Metamask connection found! Please use wallet connect or try inside the metamask app.")
        }
    } else {
        try {
            provider = new WalletConnectProvider({
                infuraId: INFURAID, // Required
            });
            const connect = await provider.enable();
            address = ethers.utils.getAddress(connect[0].toString());

        } catch (err) {
            console.log("ERROR", err)
        }
    }


    const chainId = await provider.request({method: "eth_chainId"})
    if (chainId !== MAINNET_CHAIN_ID) {
        await provider.request({
            method: 'wallet_switchEthereumChain',
            params: [{chainId: MAINNET_CHAIN_ID}]
        });
    }

    // init ethers provider using the web3 provider we just connected to
    const ethersProvider = new ethers.providers.Web3Provider(provider)
    return {walletAddress: address, provider: provider, ethersProvider: ethersProvider}

}

export const publicMintNow = async (walletAddress, batchSize, ethersProvider) => {
    console.log(ethersProvider)
    const encodedParams = ethers.utils.defaultAbiCoder.encode(
        ["uint256"], [batchSize]
    )
    const calldata = PUBLIC_MINT_FUNCTION_SELECTOR + encodedParams.slice(2)
    const txHash = await ethersProvider.request({
        method: 'eth_sendTransaction',
        params: [{
            from: walletAddress,
            to: CONTRACT_ADDRESS,
            value: '0x' + ((MINT_PRICE_WEI) * batchSize).toString(16),
            data: calldata,
        }]
    })
    return await ethersProvider.waitForTransaction(txHash)
    console.log("MINED")
}